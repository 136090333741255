import * as React from "react";

import { Helmet } from "react-helmet";

import classNames from "classnames";
import { Button, Card, Container, Row, Col, Carousel, Table } from "react-bootstrap"

import PageLayout from "../components/PageLayout";
import HeroBanner from "../components/HeroBanner";
import PlainModule from "../components/PlainModule";

import { playDohImageWrapper, playDohImage, concreteContainer, cardContainer, cardCol, cementImage } from "./casestudies.module.scss"

import PetriDishes from "../images/iStock-175449789.jpg";

import PlayDohCyan from "../images/Cyan_2_0.png";
import PlayDohOrange from "../images/Orange_2_0.png";
import PlayDohPink from "../images/Pink_2_0.png";
import PlayDohPurple from "../images/Purple_2_0.png";

import Cement0 from "../images/cement_0.png";
import Cement1 from "../images/cement_1.png";
import Cement2 from "../images/cement_2.png";

const CaseStudiesPage = () => {

  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>Tayos Corp - TC&#8209;X1 Electromagnetic Conductivity Sensor - Play-doh and Concrete Case Studies</title>
      </Helmet>
      <HeroBanner backgroundImage={PetriDishes} backgroundShift="44%" />

      <PlainModule>
        <Container>
          <Row>
            <Col sm={12} lg={6} xl={7} xxl={8}>
              <h1>Evaluating Quality Control of Play-doh with the TC&#8209;X1</h1>
              <p className="lead text-muted">Measure Samples of Every Kind and Color</p>
              <p className="uberPaddedP">
                Play-doh is a non-toxic and moldable material, which makes it perfect to demonstrate the capabilities of the TC&#8209;X1.  The TC&#8209;X1 makes a side-by-side comparison of four different colors of play-doh simple: we only need to fill the petri dish to one centimeter deep and place the dish centered on the sensor.  This case study shows that different play-doh containers and colors have very consistent electrical conductivity accross the different colors. This consistency indicates tight quality control is likely used in manufacturing process.
              </p>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>
                      Play-doh Color
                    </th>
                    <th>
                      Average Conductivity (mS/cm)
                    </th>
                    <th>
                      Standard Deviation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Purple</td>
                    <td>32.49</td>
                    <td>0.60</td>
                  </tr>
                  <tr>
                    <td>Cyan</td>
                    <td>33.99</td>
                    <td>0.48</td>
                  </tr>
                  <tr>
                    <td>Orange</td>
                    <td>34.10</td>
                    <td>0.58</td>
                  </tr>
                  <tr>
                    <td>Pink</td>
                    <td>32.41</td>
                    <td>0.48</td>
                  </tr>
                </tbody>
              </Table>
              <Button className="mb-lg-0 mb-3" variant="outline-secondary" href="/manual/">Read the Manual</Button>
            </Col>
            <Col sm={12} lg={6} xl={5} xxl={4}>
              <Carousel fade variant="dark">
                <Carousel.Item>
                  <div className={playDohImageWrapper}>
                    <img className={classNames("d-block", playDohImage)} src={PlayDohPurple} alt="TC-X1 showing a reading of 30.33 mS/cm with purple plah-doh in a petri dish on the sensor" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className={playDohImageWrapper}>
                    <img className={classNames("d-block", playDohImage)} src={PlayDohCyan} alt="TC-X1 showing a reading of 30.97 mS/cm with cyan plah-doh in a petri dish on the sensor" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className={playDohImageWrapper}>
                    <img className={classNames("d-block", playDohImage)} src={PlayDohOrange} alt="TC-X1 showing a reading of 31.96 mS/cm with orange plah-doh in a petri dish on the sensor" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className={playDohImageWrapper}>
                    <img className={classNames("d-block", playDohImage)} src={PlayDohPink} alt="TC-X1 showing a reading of 31.76 mS/cm with pink plah-doh in a petri dish on the sensor" />
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </PlainModule>

      <PlainModule className={concreteContainer}>
        <Container>
          <h1>Measuring how concrete dries with the TC&#8209;X1</h1>
          <p className="lead text-muted">A cleaner way to work with messy samples</p>
          <p className="uberPaddedP">
          A study with the TC&#8209;X1 clearly shows the progression of electrical conductivity as Quikrete Concrete Mix cures over a period of 6 days.  Curing can be affected by ambient temperature, humidity, the presence of additives, as well as other factors.  Measurement by existing technology is inconvenient, time-consuming, and fraught with error as seen in{" "}
            <a 
              href="https://curve.carleton.ca/system/files/etd/6a2860e2-1cce-4820-b5d0-6e22342aa39d/etd_pdf/e721935213f946eacac44ac94bd312e3/sallehi-characterizationofcementpasteinfreshstate.pdf"
              aria-label="Read 'Characterization of Concrete Paste in Fresh State Using Electrical Resistivy Technique' (Master's Thesis by Hossein Sallehi)">
              contemporary studies
            </a>.  By comparison, taking measurements of uncured concrete with the TC&#8209;X1 is quick and clean - practical for studies of various mixes, or even useful for studying or monitoring large infrastructure using concrete construction.
          </p>
          <Row className={cardContainer}>
            <Col sm={12} md={6} lg={4} className={cardCol}>
              <Card bg="dark" className="text-center m-2 p-4 shadow" border="secondary">
                  <Card.Img src={Cement0} variant="top" className={classNames("mx-auto", cementImage)} alt="Freshly mixed Quikrete Concrete Mix on the TC-X1 sensor" />
                <Card.Body>
                  <Card.Title>Fresh Concrete</Card.Title>
                  <Card.Text>
                    Freshly mixed Quikrete Concrete Mix, placed into a standard petri dish at a standard depth of 1.0 cm.  Conductivity at time of mixing is 8.24 mS/cm.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={4} className={cardCol}>
              <Card bg="dark" className="text-center m-2 p-4 shadow" border="secondary">
                  <Card.Img src={Cement1} variant="top" className={classNames("mx-auto", cementImage)} alt="Day-old Quikrete Concrete Mix on the TC-X1 sensor" />
                <Card.Body>
                  <Card.Title>24 Hour Concrete</Card.Title>
                  <Card.Text>
                  Quikrete Concrete Mix paste 24 hours after initial mixing and added to a standard petri dish.  Conductivity has already decreased to 7.16 mS/cm due to partial curing.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={4} className={cardCol}>
              <Card bg="dark" className="text-center m-2 p-4 shadow" border="secondary">
                  <Card.Img src={Cement2} variant="top" className={classNames("mx-auto", cementImage)} alt="Quikrete Concrete Mix after setting six days on the TC-X1 sensor" />
                <Card.Body>
                  <Card.Title>6 Day Concrete</Card.Title>
                  <Card.Text>
                  Quikrete Concrete Mix paste 6 days after initial mixing and partially cured in standard petri dish.  Conductivity has decreased to 1.81 mS/cm – further decrease is still expected as the cure process continues.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </PlainModule>

      <PlainModule>
        <Container>
          <h1>New Applications Made Every Day</h1>
          <p className="lead text-muted">A Flexible Tool for a Demanding Laboratory</p>
          <p className="uberPaddedP">
            The TC&#8209;X1 was designed to sample a vast variety of materials, and make doing so easier than ever before.  Still not sure if the TC&#8209;X1 fits your needs?  Contact us today and set up a demonstration of how the TC&#8209;X1 can be used to in your lab.  We're working hard to make our sensors do more every day with new software and mathematics, so check back to see more studies and products!
          </p>
          <Button href="/contact/?template=order">Order Now!</Button>
          <Button variant="outline-secondary" href="/contact/?template=demo">Get a Demo</Button>
        </Container>
      </PlainModule>
    </PageLayout>
  )
}

export default CaseStudiesPage
